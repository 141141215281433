import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '160px',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '42px',
    fontFamily: 'AvenirNextCyr, ProximaNova, sans-serif',
    fontWeight: '400'
  },
  timelineBox: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  jobBoxRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: '0',
    zIndex: '3',
  },
  overlayBoxRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: '0',
  },
  jobBox: {
    width: '100%',
    height: '145px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  overlayJobBox: {
    width: '100%',
    height: '160px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  jobLine: {
    height: '5px',
  },
  jobText: {
    fontSize: '0.92rem',
    textAlign: 'center',
    margin: '0 auto 10px',
    padding: '2px',
    [theme.breakpoints.down('xs')]: {
      writingMode: 'vertical-rl',
      transform: 'rotate(180deg)',
      textAlign: 'left',
    },
  },
  jobTitleBox: {
    zIndex: '4',
    textAlign: 'center',
    height: '200px',
    position: 'absolute',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100px',
    top: '0px',
    backgroundColor: 'transparent',
    opacity: '0',
    transition: 'opacity 0.5s',
    '&:hover': {
      opacity: '0.95',
    },
  },
  jobTitle: {
    textAlign: 'center',
    width: '100px',
    marginTop: '0px',
    padding: '3px',
    borderRadius: '5px',
    backgroundColor: '#373737',
  },
  yearBox: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  line: {
    height: '1px',
    width: '100%',
    backgroundColor: '#828282',
  },
  tick: {
    height: '15px',
    width: '2px',
    backgroundColor: '#828282',
    position: 'absolute',
  },
  year: {
    fontSize: '0.86rem',
    color: '#828282',    
    position: 'absolute',
    top: '10px',
    left: '5px',
    [theme.breakpoints.down('xs')]: {
      transform: 'rotate(-90deg)',
      top: '20px',
      left: '-15px',
    },
  },
  overlayBackground: {
    height: '75px',
    backgroundColor: '#373737',
  },
}));

export default useStyles;
